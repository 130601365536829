<template>
  <div class="legal-foot-note">
    <p class="">
      <span>1.&nbsp;</span>
      <amui-i18n :term="$t('legal.consumptionAndEmission')" tag="span">
        <a slot="link" href="https://www.dat.de/co2/" target="_blank">
          https://www.dat.de/co2/
        </a>
      </amui-i18n>
    </p>
    <p v-for="d in crossedOutPriceLegalNotes" :key="d.vehicleTypes.join('')">
      <span>{{ d.referenceNumber }}.</span>
      {{ d.text }}
    </p>
  </div>
</template>

<script>
import { crossedOutPriceLegalNotes as _crossedOutPriceLegalNotes } from '@/shared/definitions/legal'
import { AmuiI18n } from '@/../ui/components/i18n'

export default {
  name: 'AmLegalFootNote',

  components: {
    AmuiI18n
  },

  computed: {
    crossedOutPriceLegalNotes() {
      return _crossedOutPriceLegalNotes.map(data => {
        return Object.assign({}, data, {
          text: this.$t(data.text)
        })
      })
    }
  }
}
</script>
