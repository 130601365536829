var render = function () {
var _obj, _obj$1;
var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(_vm.componentTag,{tag:"component",staticClass:"am-vehicle-tile-extended",class:{
    'am-vehicle-tile-extended--favored': _vm.isFavorite
  },attrs:{"href":_vm.sold ? null : _vm.vehicleUrl},on:{"click":function($event){$event.stopPropagation();$event.preventDefault();return _vm.onViewVehicle.apply(null, arguments)}}},[_c('div',{staticClass:"am-vehicle-tile-extended__inner"},[_c('div',[_c('button',{staticClass:"am-vehicle-tile-extended__fav-button",attrs:{"aria-label":_vm.isFavorite
            ? _vm.$t('favorites.vehicle.remove')
            : _vm.$t('favorites.vehicle.add')},on:{"click":function($event){$event.stopPropagation();$event.preventDefault();return _vm.$emit('favour')}}},[_c('amui-icon',{attrs:{"name":_vm.favoriteIconName}})],1),_c('div',{staticClass:"am-vehicle-tile-extended__subtitle"},[_vm._v(" "+_vm._s(_vm.make)+" "+_vm._s(_vm.amModelYear)+" ")]),_c('div',{staticClass:"am-vehicle-tile-extended__title"},[_vm._v(_vm._s(_vm.model))]),_c('div',{staticClass:"am-vehicle-tile-extended__chips"},[(_vm.usageType !== null)?_c('amui-flex-chip',{staticClass:"am-vehicle-tile-extended__chip-usage-type",class:{
            'am-vehicle-tile-extended__chip-usage-type--primary':
              _vm.usageType.value === 'NEW'
          },attrs:{"label":_vm.usageType.label}}):_vm._e(),(!_vm.sold && _vm.availabilityInWeeks === 0)?_c('amui-flex-chip',{staticClass:"am-vehicle-tile-extended__chip-availability",attrs:{"label":_vm.availabilityLabel}}):_vm._e(),(
            !_vm.sold && _vm.availabilityInWeeks !== null && _vm.availabilityInWeeks > 0
          )?_c('div',{staticClass:"am-vehicle-tile-extended__availability-calendar"},[_c('amui-icon',{attrs:{"name":"calendar-today"}}),_c('span',[_vm._v(_vm._s(_vm.availabilityLabel))])],1):_vm._e(),(_vm.sold)?_c('amui-flex-chip',{staticClass:"am-vehicle-tile-extended__chip-sold",attrs:{"label":"Verkauft"}}):_vm._e()],1),(_vm.sold)?_c('div',{staticClass:"am-vehicle-tile-extended__sold-image-placeholder"},[_c('div',{staticClass:"am-vehicle-tile-extended__sold-image-placeholder-content"},[_c('amui-icon',{attrs:{"name":"not-interested"}}),_c('p',[_vm._v("Verkauft")])],1)]):_c('div',{staticClass:"am-vehicle-tile-extended__image-wrap"},[_c('amui-image',{staticClass:"am-vehicle-tile-extended__image",attrs:{"sources":_vm.sources,"fallback-source":_vm.fallbackSource,"alt":_vm.title,"ratio":"3:2","object-fit":"contain"}})],1),(_vm.exterior || _vm.interior)?_c('div',{staticClass:"am-vehicle-tile-extended__colors"},[(_vm.exterior)?_c('div',{staticClass:"am-vehicle-tile-extended__feature"},[_c('amui-icon',{class:( _obj = {
              'am-vehicle-tile-extended__feature-icon': true
            }, _obj['am-vehicle-tile-extended__feature-icon--' +
              (_vm.exterior.iconColor || '').toLowerCase()] = _vm.exterior.iconColor, _obj ),attrs:{"name":_vm.exterior.icon}}),_vm._v(_vm._s(_vm.exterior.label)+" ")],1):_vm._e(),(_vm.interior)?_c('div',{staticClass:"am-vehicle-tile-extended__feature"},[_c('amui-icon',{class:( _obj$1 = {
              'am-vehicle-tile-extended__feature-icon': true
            }, _obj$1['am-vehicle-tile-extended__feature-icon--' +
              (_vm.interior.iconColor || '').toLowerCase()] = _vm.interior.iconColor, _obj$1 ),attrs:{"name":_vm.interior.icon}}),_vm._v(_vm._s(_vm.interior.label)+" ")],1):_vm._e()]):_vm._e(),_c('div',{staticClass:"am-vehicle-tile-extended__features"},_vm._l((_vm.computedFeatures),function(feature,index){return _c('div',{key:feature.label + index,staticClass:"am-vehicle-tile-extended__feature"},[_c('amui-icon',{staticClass:"am-vehicle-tile-extended__feature-icon",attrs:{"name":feature.icon}}),_vm._v(_vm._s(feature.label)+" ")],1)}),0),(_vm.customerHighlights.length)?_c('div',{staticClass:"am-vehicle-tile-extended__highlights"},[_vm._v(" "+_vm._s(_vm.customerHighlights.map(function (c) { return c.label; }).join(', '))+" ")]):_vm._e()]),_c('div',[(_vm.price)?_c('div',{staticClass:"am-vehicle-tile-extended__prices"},[_c('div',{staticClass:"am-vehicle-tile-extended__price-row-top"},[(_vm.crossedOutPrice)?[_c('span',[_vm._v(_vm._s(_vm.crossedOutPrice))]),_c('sup',[_vm._v(" "+_vm._s(_vm.crossedOutPriceReferenceNumber))])]:_vm._e()],2),_c('div',{staticClass:"am-vehicle-tile-extended__price-row"},[_c('div',{staticClass:"am-vehicle-tile-extended__purchase-price",class:{
              'am-vehicle-tile-extended__purchase-price--highlighted': _vm.crossedOutPrice
            }},[_vm._v(" "+_vm._s(_vm.price)+" ")]),(_vm.rate)?_c('div',{staticClass:"am-vehicle-tile-extended__monthly-price"},[_vm._v(" "+_vm._s(_vm.rate)+" ")]):_vm._e()]),_c('div',{staticClass:"am-vehicle-tile-extended__price-row-bottom"},[_c('div',[_vm._v(_vm._s(_vm.priceTaxInfo))]),(_vm.rate)?_c('div',[_vm._v("mtl. ab")]):_vm._e()])]):_vm._e(),_vm._t("integration"),(!_vm.isMotorbike && (!_vm.sold || _vm.efficiencyText))?_c('div',{staticClass:"am-vehicle-tile-extended__efficiency"},[_c('span',[_vm._v(_vm._s(_vm.efficiencyText)),(_vm.efficiencyText)?_c('sup',[_vm._v("1")]):_vm._e()])]):_vm._e()],2)])])}
var staticRenderFns = []

export { render, staticRenderFns }