<template>
  <div class="amui-compact-toolbar">
    <div class="amui-compact-toolbar__slot"><slot></slot></div>
  </div>
</template>

<script>
export default {
  name: 'AmuiCompactToolbar'
}
</script>
