<template>
  <div class="amui-flex-chip">
    <span>{{ label }}</span>
  </div>
</template>

<script>
export default {
  name: 'AmuiFlexChip',

  props: {
    label: {
      type: String,
      required: true
    }
  }
}
</script>
<style lang="scss">
.amui-flex-chip {
  font-family: var(--amui-font-family);
  font-size: var(--amui-flex-chip-size, 16px);
  line-height: 0;
  background-color: var(--amui-flex-chip-background-color, #000000);
  color: var(--amui-flex-chip-color, #ffffff);
  border-radius: var(--amui-flex-chip-border-radius);
  display: inline-flex;
  align-items: center;
  justify-content: center;
  padding: 0.125em 0.5em;
  overflow: hidden;
  height: 1.25em;

  > span {
    font-style: normal;
    font-weight: var(--amui-flex-chip-font-weight);
    font-size: 0.8125em; // 13px
    line-height: 1.2;
    letter-spacing: 0.05em;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
}
</style>
