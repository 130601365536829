<template>
  <component :is="componentTag" :href="href" class="amui-compact-link">
    <div>
      <amui-icon :name="icon" />
      <amui-badge
        :label="badge"
        class="amui-compact-link__badge"
        small
        v-if="badge"
      />
    </div>
    <span>{{ label }}</span>
  </component>
</template>

<script>
import { AmuiIcon } from '@/../ui/components/icon'
import { AmuiBadge } from '@/../ui/components/badge'

export default {
  name: 'AmuiCompactToolbar',

  components: {
    AmuiIcon,
    AmuiBadge
  },

  props: {
    label: {
      type: String,
      required: true
    },
    icon: {
      type: String,
      required: true
    },
    badge: {
      type: Number,
      required: false,
      default: null
    },
    href: {
      type: String,
      required: false,
      default: null
    }
  },

  computed: {
    componentTag() {
      return this.href ? 'a' : 'button'
    }
  }
}
</script>
